import ListBlankCourier from '@/views/master/courier/components/ListBlankCourier.vue'
import FilterDatePayout from '@/views/master/courier/comission-confirmation/components/FilterDatePayout.vue'
import TableComissionPayment from '@/views/master/courier/comission-payment/components/TableComissionPayment.vue'
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  comissionConfirmationUseCase,
  comissionPaymentUseCase,
  getListAgent
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'

export default {
  name: 'PeriodPayment',
  components: {
    ListBlankCourier,
    FilterDatePayout,
    TableComissionPayment
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const dateOp = ref()
    const store = useStore()
    const dataSource = ref([])
    const dataSourceFilter = ref([])
    const filterDate = ref(null) as any
    const dataTable = ref([])
    const refTableComissionPayment = ref()
    const refTableComissionConfirmation = ref()
    const listAgent = ref()
    const agentOp = ref()
    const filtersAgent = ref({
      value: null,
      nama: 'Semua Agen',
      label: 'Semua Agen'
    })
    const showDropDown = (event: any) => {
      dateOp.value.toggle(event)
    }
    const getFilterDate = () => {
      store.dispatch('showLoading')
      comissionConfirmationUseCase.getFilterDatePayout().then((response: any) => {
        dataSourceFilter.value = response.result
        store.dispatch('hideLoading')
      })
    }
    const fetchDataTable = () => {
      store.dispatch('showLoading')
      let custom
      if (filtersAgent.value.value) {
        custom = `?agenid=${filtersAgent.value.value}`
      }
      comissionConfirmationUseCase.getCustomPayout(filterDate.value, {
        custom
      }).then((response: any) => {
        if (!response.error) {
          dataTable.value = response.result.map((x: any) => {
            x.Konfirmasi = x.IsConfirmed ? x.Total : 0
            return x
          })
          dataSource.value = response.result.map((x: any) => {
            x.Konfirmasi = x.IsConfirmed ? x.Total : 0
            return x
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.result.detail,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const processSubmit = () => {
      // console.log('aaa', refTableComissionPayment.value)
      const tempData = refTableComissionPayment.value.data.filter((x: any) => x.IsConfirmed === true)
      const tempStatus = refTableComissionPayment.value.data.filter((x: any) => x.PaymentStatus !== 'unpaid')
      // console.log('tempData', tempData)
      // console.log('tempStatus', tempStatus)
      // console.log('tempStatus', tempStatus.length)
      if (tempStatus.length === 0) {
        store.dispatch('showLoading')
        // eslint-disable-next-line arrow-body-style
        const mapTempData = tempData.map((x: any) => {
          return {
            Id: x.Id,
            IsConfirmed: x.IsConfirmed
          }
        })
        console.log('mapTempData', mapTempData)
        comissionPaymentUseCase.submitApprovePayout(mapTempData).then((response: any) => {
          if (!response.error) {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            fetchDataTable()
          } else {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          }
          store.dispatch('hideLoading')
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan Semua Status Pembayaran Masih Belum Terisi',
          group: 'bc',
          life: 3000
        })
      }
    }

    const saveList = () => {
      // if (refTableComissionConfirmation.value.isValidData() === true) {
      processSubmit()
      // } else {
      //   $toast.add({
      //     severity: 'error',
      //     detail: 'Pastikan nominal kolom Konfirmasi sama dengan kolom Total',
      //     group: 'bc',
      //     life: 3000
      //   })
      // }
    }

    const showDropdownFromAgent = (evt: any) => {
      agentOp.value.toggle(evt)
    }

    const selectedAgent = (evt: any, item: any) => {
      filtersAgent.value.value = item.value
      filtersAgent.value.label = item.label
      filtersAgent.value.nama = item.nama
      fetchDataTable()
      agentOp.value.toggle(evt)
    }

    const getAgents = async () => {
      const response = await getListAgent({
        select: '&$select=Id,Kode,Nama,KotaId',
        expand: '&$expand=Kota($select=Id,Nama,Kode)'
      })
      if (!response.error) {
        listAgent.value = response.result.map((item: any) => ({
          label: `${item.Nama} (${item.Kota.Nama})`,
          nama: item.Nama,
          value: item.Id
        }))
      }
      listAgent.value.unshift({ label: 'Semua Agen', value: null })
    }

    const submitDateFilter = (val: any) => {
      filterDate.value = val.CutOffId
      fetchDataTable()
      dateOp.value.hide()
    }

    onMounted(() => {
      getFilterDate()
      getAgents()
    })
    return {
      showDropDown,
      dateOp,
      dataSource,
      submitDateFilter,
      moment,
      filterDate,
      saveList,
      dataTable,
      fetchDataTable,
      refTableComissionConfirmation,
      refTableComissionPayment,
      listAgent,
      selectedAgent,
      agentOp,
      showDropdownFromAgent,
      filtersAgent,
      dataSourceFilter
    }
  }
}
